import React from 'react'
import Logo from '../Static/Image/Logo.png'

const Footer = () => {
  return (
    <div className='w-full h-auto mb-[80px]'>
        <div className='w-full h-auto flex justify-center items-center mt-[100px]'>
            <a href='/'><img src={Logo} alt='' className='w-[216px] md:w-[250px] h-[55px] md:h-[60px]'/></a>
        </div>
        <div className='w-full h-auto flex justify-center items-center mt-8'>
            <a href='' className='flex justify-center items-center text-1xl text-black font-bold'>Contact us at: support@torqoise.com</a>
        </div>
        <div className='w-full h-auto  mt-[80px]'>
            <div className='flex justify-between items-center  flex-row w-[200px] m-auto'>
            <a href='' className='flex justify-center items-center text-1xl text-[gray]' >Support</a>
            <a href='' className='flex justify-center items-center text-1xl text-[gray]' >Privacy</a>
            <a href='' className='flex justify-center items-center text-1xl text-[gray]' >Terms</a>
            </div>
        </div>
        <div className='w-full h-auto flex justify-center items-center mt-4 '>
            <p className='text-1xl text-[gray]'>©{new Date().getFullYear()} Torquise.com All rights reserved.</p>
        </div>
    </div>
  )
}

export default Footer