import React from 'react';
import feature1 from '../Static/Image/img/feature-1.png'
import feature2 from '../Static/Image/img/feature-2.png'
import feature3 from '../Static/Image/img/feature-3.png'

const HeroSectionD = () => {
  return (
    <div className='w-full h-auto'>
    <div className='max-w-[1280px] m-auto'>
    <div className='w-full h-auto mt-20 px-4 md:px-0 lg:px-0 '>
       <div className='w-full h-auto m-auto '>
        <div className="w-full flex justify-center pt-10 lg:pt-0">
            <h1 className="text-[36px] md:text-[48px] lg:text-[64px] bg-gradient-to-r from-[#008099] to-[#00B4D8] bg-clip-text text-transparent font-bold">Key Features</h1>
        </div>

        <div className="flex justify-center px-5 md:px-10 lg:px-0">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mt-12 lg:px-12 ">
              
                  <div className="p-7 bg-gradient-to-tr from-[#F5FAFE] via-[#F5FAFE] to-[#42eff5] rounded-[24px]">
                    <img src={feature1} className="w-full max-w-full" />
                    <p className="mt-5 font-semibold text-[18px] md:text-[22px]">AI Base Drag-and-Drop Builder</p>
                    <p className="text-[16px] md:text-[18px]">Effortlessly create your digital presence.</p>
                  </div>

                  <div className="p-7 bg-gradient-to-tr from-[#F5FAFE] via-[#F5FAFE] to-[#42eff5] rounded-[24px] ">
                    <img src={feature2} className="w-full max-w-full" />
                    <p className="mt-5 font-semibold text-[18px] md:text-[22px]">AI Revenue Maximizer</p>
                    <p className="text-[16px] md:text-[18px]">Optimize ROI, analyze feedback, and forecast demand with AI.</p>
                  </div>

                  <div className='md:col-span-2 lg:col-span-1 col-span-1 md:max-w-[50%] lg:max-w-full max-w-full md:m-auto lg:m-0 max-h-full'>
                  <div className="p-7 bg-gradient-to-tr from-[#F5FAFE] via-[#F5FAFE] to-[#42eff5] rounded-[24px]  pb-[60px] ">
                    <img src={feature3} className="w-full max-w-full h-full" />
                    <p className="mt-5 font-semibold text-[18px] md:text-[22px]">One-Click Dynamic Ads</p>
                    <p className="text-[16px] md:text-[18px]">Launch impactful ad campaigns in seconds.</p>
                  </div>
                  </div>

              </div>
          </div>

      </div>
    </div>
    </div>
    </div>
  )
}

export default HeroSectionD