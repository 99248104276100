import React from 'react'
import Image1 from '../Static/Image/SectionB/Image1.jpg'
import Image2 from '../Static/Image/SectionB/Image2.jpg'
import Image3 from '../Static/Image/SectionB/Image3.jpg'
import Image4 from '../Static/Image/SectionB/Image4.jpg'
import spark from '../Static/Image/spark.png'


const HeroSectionB = () => {
   const sectB_data = [
        {"SBIMG":Image1,content1:"Hotels",content2:"Elevate your brand, boost bookings, and streamline operations."},
        {"SBIMG":Image2,content1:"Managed Apartments",content2:"Maximize occupancy and simplify management."},
        {"SBIMG":Image3,content1:"Banquets",content2:"Increase event bookings with ease."},
        {"SBIMG":Image4,content1:"Spas",content2:"Drive loyalty and grow revenue effortlessly."},
    ]
   
  return (
    <div className='w-full h-auto mt-20 bg-[#00B4D8] rounded-tl-[30px] rounded-tr-[30px] '>
       <div className='w-full lg:max-w-[1206px] h-auto m-auto '>
         <div className=' w-full max-auto h-auto bg-[#00B4D8] rounded-tl-[30px] rounded-tr-[30px] md:mt-18 mt-4 mb-10 pt-[60px]  md:pl-10 md:pr-10 pl-8 pr-8 pb-10 flex flex-col justify-center items-start xl:m-auto'>
            <div className='relative w-full mb-15'>
                <p className='lg:text-[64px] md:text-[48px] text-[36px] text-white font-semibold'> Who is it for?</p>
                <span className='absolute left-[200px] top-[-40px] md:left-[260px] lg:left-[400px] lg:top-[-35px]'>
                    <img src={spark} alt='photo' className='w-[63px] h-[53px]' />
                </span>
            </div>
            <br/>


            <div className='w-full grid grid-cols-1 md:grid-cols-2 md:gap-x-10 md:gap-y-10  gap-y-10'>
                {sectB_data.map((item,index)=>{
                    return(
                        <div className='lg:max-w-[625px] lg:max-[331px] w-full md:h-full lg:h-auto bg-white rounded-[30px] mx-auto lg:pl-0 lg:pr-0 lg:pt-6 lg:pb-6    overflow-hidden custom-sectionB-Pdiv' key={index}>
                            <div className='w-auto h-full  grid lg:grid-cols-3 grid-cols-1 lg:pl-6 lg:pr-0 '>
                                <div className='flex flex-col justify-start items-start col-span-1 box-border lg:pr-4 lg:mb-0 custom-sectionB-Pdiv-p1'>
                                    <p className='text-[18px] md:text-[22px] lg:text-[29px] font-bold leading-8 text-pretty lg:mb-4 lg:mt-2'>{item.content1}</p>
                                    <p className='text-[16px] md:text-[18px] lg:text-[18px]'>{item.content2}</p>
                                </div>
                                <div className='w-full flex justify-center items-center md:col-span-2 col-span-1 overflow-hidden  lg:h-full p-0 custom-sectionB-Pdiv-p2'>
                                <img 
                                    src={item.SBIMG} 
                                    className="w-full lg:h-[259px] h-full lg:rounded-tl-[24px] lg:rounded-bl-[24px] lg:rounded-tr-[0px] md:rounded-tl-[24px] md:rounded-tr-[24px]  sm:rounded-tl-[24px] sm:rounded-tr-[24px]" 
                                    />

                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>


         </div>
    </div>
     </div>
   
  )
}

export default HeroSectionB