import React from 'react'
import Isolation_Mode from '../Static/Image/Isolation_Mode.png'

const HeroSectionE = () => {
  return (
    <div className='w-full h-auto'>
    <div className='max-w-[1280px] m-auto'>
      <div className='w-full  h-auto m-auto px-4 md:px-2 lg:px-1 '>
        <div className='flex md:flex-row flex-col md:items-center md:justify-between items-center justify-center md:mx-7 mx-4 lg:mx-10 h-auto bg-[#00B4D8] rounded-[30px] px-1 md:px-6 lg:px-[60px] box-border py-2 duration-300 '>
            <div className='flex flex-col md:items-start items-center justify-center text-white md:w-[50%] w-full mt-8 md:mt-0 lg:pl-4'>
                <p className='text-white text-[33px] md:text-[36px] lg:text-[48px] font-semibold mb-2'>Pricing</p>
                <div className='px-2 md:px-0 lg:px-0'>
                  <p className='text-white text-[16px] md:text-[16px]  lg:text-[18px]'>Flexible pricing tailored to your needs.</p>
                  <p className='text-white text-[16px] md:text-[16px] lg:text-[18px]'>Contact us for a personalized quote.</p>
                </div>
            </div>
            <div className='flex md:flex-row flex-col md:items-center md:justify-between items-center justify-center mt-4 h-auto w-auto md:px-4 px-1 md:gap-20 gap-8'>
                <div className='grid items-end justify-end'>
                    <img src={Isolation_Mode} alt='isolation' className='w-[133px] h-[133px] md:w-[165px] md:h-[165px]  lg:w-[196px] lg:h-[196px]'/>
                </div>
                <div className='grid items-center pb-8 md:pb-auto'><a href='/client-info' className='w-[160px] h-[50px] bg-white text-[#00B4D8] rounded-[12px] text-[24px] flex justify-center items-center text-center font-bold pt-1'>Let's Talk</a></div>
            </div>
        </div>
      </div>
     </div>
    </div>

  )
}

export default HeroSectionE