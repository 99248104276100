import React from 'react'
import './style.css';

const HeroSectionF = () => {
  return (
    <div className='w-full h-auto my-10 lg:flex flex pb-[100px]'>
      <div className='w-full lg:max-w-[1206px] h-auto m-auto '>
        <div className="px-12 pt-12 mt-12">

            <p className="text-[36px] md:text-[48px] lg:text-[50px] font-bold text-center md:text-left lg:text-center">How to <span className="bg-gradient-to-r from-[#008099] to-[#00B4D8] bg-clip-text text-transparent font-bold">Use AI</span> in</p>

            <p className="text-[36px] md:text-[48px] lg:text-[50px]  lg:mt-2 font-bold text-center md:text-left lg:text-center">your Marketing</p>

        </div>  
        
           {/*Desktop version  */}
       <div className='herosect-f-div'>
              <div className="flex justify-center relative ml-[100px]">

                 <div className="marketing grid grid-cols-1 lg:grid-cols-6 gap-0 pl-8 pr-[-800px] mt-7 mb-5" style={{width:'100%'}}>

                      <div className="py-10 lg:py-3 pl-[-100px] dot-div">
                          <p className="dot  text-sm p-2 w-10 h-10 text-center flex justify-center items-center bg-[#00B4D8]  rounded-[100%] text-white">01</p> 
                      </div>

                      <div className="py-10 lg:py-3 dot-div">
                          <p className="dot  text-sm p-2 w-10 h-10 text-center flex justify-center items-center bg-[#00B4D8]  rounded-[100%] text-white">02</p> 
                      </div>

                      <div className="py-10 lg:py-3 dot-div">
                          <p className="dot  text-sm p-2 w-10 h-10 text-center flex justify-center items-center bg-[#00B4D8]  rounded-[100%] text-white">03</p> 
                      </div>

                      <div className="py-10 lg:py-3 dot-div">
                          <p className="dot  text-sm p-2 w-10 h-10 text-center flex justify-center items-center bg-[#00B4D8]  rounded-[100%] text-white">04</p> 
                      </div>

                      <div className="py-10 lg:py-3 dot-div">
                          <p className="dot  text-sm p-2 w-10 h-10 text-center flex justify-center items-center bg-[#00B4D8]  rounded-[100%] text-white">05</p> 
                      </div>

                      <div className="py-10 lg:py-3 ">
                          <p className="dot  text-sm p-2 w-10 h-10 text-center flex justify-center items-center bg-[#00B4D8]  rounded-[100%] text-white">06</p> 
                      </div>

                </div>
            </div>

            <div className="flex justify-center">

                <div className="grid grid-cols-1 lg:grid-cols-6 gap-5 px-12 my-12">

                <div className="px-4 py-4 border-2 rounded-[20px] h-auto w-auto">
                <p className="font-bold text-[22px]">Set Up</p>
                <p className="text-[18px]">Enter your property details and select from target audience recommendations</p> 
                </div>

                <div className="px-4 py-4 border-2 rounded-[20px] h-auto w-auto">
                <p className="font-bold text-[22px]">Customize</p>
                <p className="text-[18px]">Design your site with the AI-guided drag-and-drop editor.</p> 
                </div>

                <div className="px-4 py-4 border-2 rounded-[20px] h-auto w-auto">
                <p className="font-bold text-[22px]">Automate</p>
                <p className="text-[18px]">Create ads with a single click.</p> 
                </div>

                <div className="px-4 py-4 border-2 rounded-[20px] h-auto w-auto">
                <p className="font-bold text-[22px]">Analyze</p>
                <p className="text-[18px]">Use AI to track and optimize campaigns</p> 
                </div>

                <div className="px-4 py-4 border-2 rounded-[20px] h-auto w-auto">
                <p className="font-bold text-[22px]">Optimize Pricing</p>
                <p className="text-[18px]">Adjust rates in real time with AI</p> 
                </div>

                <div className="px-4 py-4 border-2 rounded-[20px] bg-gradient-to-r from-[#008099] to-[#00B4D8]">
                <p className="font-bold text-[22px] text-white">Enhance Experience</p>
                <p className="text-[18px] text-white">Personalise marketing campaign and boostguest loyalty.</p> 
                </div>

                </div>
            </div>
       </div>

       {/* Desktop version end */}


       {/* Mobile version */}
       <div className="w-auto h-auto new-mobile-section hidden lg:hidden md:hidden px-10 pt-10 pb-[80px]">
        
          <div className='h-[633px]'>
              {/* Mobile version */}
              <div className='w-full h-[633px] mb-[150px]'>
              <div className='w-full h-full flex flex-row justify-start gap-2 items-start'>
                <div className='h-[110%] py-8 px-0 mt-6 mb-2'>
                    {/* Line number */}
                        <div className='min-w-[40px] h-full flex justify-center items-center overflow-hidden'>
                            <div className='relative w-[4px] h-full flex flex-col items-center justify-between'>
                            <div className='absolute h-full w-[4px]' style={{
                                backgroundImage: 'linear-gradient(to bottom, gray 30%, rgba(255, 255, 255, 0) 0%)',
                                backgroundPosition: 'right',
                                backgroundSize: '2px 20px',
                                backgroundRepeat: 'repeat-y',
                              }}></div>
                            <div className='w-4 h-4 p-4 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center'>
                                <span className='grid items-center text-white text-[16px]'>01</span>
                            </div>
                            <div className='w-4 h-4 p-4 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center'>
                                <span className='grid items-center text-white text-[16px]'>02</span>
                            </div>
                            <div className='w-4 h-4 p-4 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center '>
                                <span className='grid items-center text-white text-[16px]'>03</span>
                            </div>
                            <div className='w-4 h-4 p-4 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center '>
                                <span className='grid items-center text-white text-[16px]'>04</span>
                            </div>
                            <div className='w-4 h-4 p-4 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center '>
                                <span className='grid items-center text-white text-[16px]'>05</span>
                            </div>
                            <div className='w-4 h-4 p-4 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center '>
                                <span className='grid items-center text-white text-[16px]'>06</span>
                            </div>
                            </div>
                        </div>
                        {/* line number */}
                </div>
                <div className=' h-auto '>
                    {/* Hero content */}
                    <div className='w-full h-full flex flex-col justify-center items-start gap-4 pt-2'>
                        <div className=" w-full px-3 py-4 border-2 rounded-[20px] ">
                        <p className="font-bold text-[18px]">Set Up</p>
                        <p className="text-[16px]">Enter your property details and select from target audience recommendations</p> 
                        </div>

                        <div className=" w-full px-3 py-4 border-2 rounded-[20px] ">
                        <p className="font-bold text-[18px]">Customize</p>
                        <p className="text-[16px]">Design your site with the AI-guided drag-and-drop editor.</p> 
                        </div>

                        <div className="w-full px-3 py-4 border-2 rounded-[20px] ">
                        <p className="font-bold text-[18px]">Automate</p>
                        <p className="text-[16px]">Create ads with a single click.</p> 
                        </div>

                        <div className="w-full px-3 py-4 border-2 rounded-[20px]">
                        <p className="font-bold text-[18px]">Analyze</p>
                        <p className="text-[16px]">Use AI to track and optimize campaigns</p> 
                        </div>

                        <div className="w-full px-3 py-4 border-2 rounded-[20px] ">
                        <p className="font-bold text-[18px]">Optimize Pricing</p>
                        <p className="text-[16px]">Adjust rates in real time with AI</p> 
                        </div>

                        <div className="w-full px-3 py-4 mt-2 border-2 rounded-[20px] bg-gradient-to-r from-[#008099] to-[#00B4D8] ">
                        <p className="font-bold text-[18px] text-white">Enhance Experience</p>
                        <p className="text-[16px] text-white">Personalise marketing campaign and boostguest loyalty.</p> 
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>

          
        </div> 

       {/* Mobile Version */}


       {/* Tablet Version */}
       <div className="w-auto h-auto new-tablet-section hidden lg:hidden md:hidden px-[100px] pt-10 pb-[80px]">
        
          <div className='h-[633px]'>
              {/* Tablet version */}
              <div className='w-full h-[633px] mb-[100px]'>
              <div className='w-full h-full flex flex-row justify-start gap-2 items-start'>

                  <div className='w-full h-full flex flex-col justify-start items-start gap-[60px] '>
                    {/*  */}
                    <div className=" w-full px-4 py-4 border-2 rounded-[20px]">
                        <p className="font-bold text-[22px] text-right">Set Up</p>
                        <p className="text-[18px] text-right">Enter your property details and select from target audience recommendations</p> 
                        </div>

                        <div className="w-full px-4 py-4 border-2 rounded-[20px] min-h-[150px]">
                        <p className="font-bold text-[22px] text-right">Automate</p>
                        <p className="text-[18px] text-right">Create ads with a single click.</p> 
                        </div>


                        <div className="w-full px-4 py-4 border-2 rounded-[20px] min-h-[150px]">
                        <p className="font-bold text-[22px] text-right">Optimize Pricing</p>
                        <p className="text-[18px] text-right">Adjust rates in real time with AI</p> 
                        </div>

                        {/*  */}
                  </div>

                <div className='h-[106%] py-8 px-8'>
                    {/* Line number */}
                        <div className='min-w-[50px] h-full flex justify-center items-center overflow-hidden pt-10'>
                            <div className='relative w-[8px] h-full flex flex-col items-center justify-between'>
                            <div className='absolute h-full w-[4px] border-r-2  border-gray-400'></div>
                            <div className='w-5 h-5 p-5 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center'>
                                <span className='grid items-center text-white text-[16px]'>01</span>
                            </div>
                            <div className='w-5 h-5 p-5 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center'>
                                <span className='grid items-center text-white text-[16px]'>02</span>
                            </div>
                            <div className='w-5 h-5 p-5 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center '>
                                <span className='grid items-center text-white text-[16px]'>03</span>
                            </div>
                            <div className='w-5 h-5 p-5 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center '>
                                <span className='grid items-center text-white text-[16px]'>04</span>
                            </div>
                            <div className='w-5 h-5 p-5 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center '>
                                <span className='grid items-center text-white text-[16px]'>05</span>
                            </div>
                            <div className='w-5 h-5 p-5 bg-[#00B4D8] rounded-full z-10 flex justify-center items-center '>
                                <span className='grid items-center text-white text-[16px]'>06</span>
                            </div>
                            </div>
                        </div>
                        {/* line number */}
                </div>

                <div className=' h-auto  w-full'>
                    {/* Hero content */}
                    <div className='w-full h-full flex flex-col justify-center items-start gap-[60px] pt-[130px]'>
                        

                        <div className=" w-full px-4 py-4 border-2 rounded-[20px] min-h-[150px]">
                        <p className="font-bold tex4-[22px] text-left">Customize</p>
                        <p className="text-[18px] text-left">Design your site with the AI-guided drag-and-drop editor.</p> 
                        </div>

                        <div className="w-full px-4 py-4 border-2 rounded-[20px] min-h-[150px]">
                        <p className="font-bold text-[22px] text-left">Analyze</p>
                        <p className="text-[18px] text-left">Use AI to track and optimize campaigns</p> 
                        </div>

                        <div className="w-full px-4 py-4 mt-[-10px] border-2 rounded-[20px] bg-gradient-to-r from-[#008099] to-[#00B4D8] min-h-[150px]">
                        <p className="font-bold text-[22px] text-left text-white">Enhance Experience</p>
                        <p className="text-[18px] text-left text-white">Personalise marketing campaign and boostguest loyalty.</p> 
                        </div>
                    </div>
                </div>

              </div>
            </div>
        </div>

          
        </div> 

       {/* Tablet version*/}
      </div>
    </div>
  )
}

export default HeroSectionF