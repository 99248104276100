import React,{useState} from 'react'
import Logo from '../Static/Image/Logo.png';
import Footer from './Footer';
import FB1 from '../Static/Image/FormImages/Sub1.svg';
import FB2 from '../Static/Image/FormImages/Sub2.svg';
import FB3 from '../Static/Image/FormImages/Sub3.svg';
import FB4 from '../Static/Image/FormImages/Sub4.svg';
import { toast, Toaster } from 'react-hot-toast';

const Form = () => {
    const FMImages = [
        { FMIMG: FB1, content1: 'Exclusive Discounts', content2: 'Save big as an early adopter.' },
        { FMIMG: FB2, content1: 'Priority Support', content2: 'Enjoy VIP customer support.' },
        { FMIMG: FB3, content1: 'Beta Feature Access', content2: 'Get early access to new tools.' },
        { FMIMG: FB4, content1: 'Free Strategy Session', content2: 'Receive a complimentary consultation to kickstart your success.' },
    ];
// ###################### Form #################################
const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

 
  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
  

    try {
        const apiUrl = '/api-server/add-client'; 

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Network response was not ok: ${errorData.message || response.statusText}`);
        }

        // const data = await response.json();
        // // console.log('Success:', data);

        setFormData({
            name: '',
            email: '',
            phone: ''
        });

        toast.success('Form submitted successfully! Thank you.',{
            style: {
                background: 'green',
                color: 'white',
                fontSize: '16px',
                padding: '13px 10px',
                borderRadius: '8px',
            }
        });

    } catch (error) {
        console.error('Error:', error);
        if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
            toast.error('Network error: Please check if the API server is running and accessible.',{
                style: {
                    background: 'red',
                    color: 'white',
                    fontSize: '16px',
                    padding: '13px 10px',
                    borderRadius: '8px',
                }
            });
        } else {
            toast.error(`There was an error submitting the form: ${error.message}`,{
                style: {
                    background: 'red',
                    color: 'white',
                    fontSize: '16px',
                    padding: '13px 10px',
                    borderRadius: '8px',
                }
            });
        }
    }
};




// ##################### Form #######################################
  return (
    <div className='w-full h-auto mb-10'>

       <Toaster position="top-right" reverseOrder={false} />
   
        <div className='w-full h-[70px] border-b-2 border-b-stone-300 fixed z-[1000] opacity-[1] bg-[white] top-0'>
            <div className='h-full flex justify-start mx-10 items-center flex-cols-2'>
                  <div className='col-span-1'>
                    <a href='/' className='m-0 p-0'><img src={Logo} alt='Logo' className='max-w-[94.7px] max-h-[24px] max-md:w-[146px] max-mg:h-[37px] max-lg:w-[146px] max-lg:h-[37px]'/></a>
                  </div>
            </div>
        </div>
  
       <div className='max-w-[1280px] m-auto'>
       <div className='w-full h-auto mt-[50px] md:mt-0 lg:mt-0 pl-5 pr-10'>
            <div className='w-full h-auto flex md:flex-row flex-col-reverse md:justify-between md:items-center justify-start items-start gap-4 mt-[100px] md:mt-[80px] lg:mt-[100px]'>
                <div className='w-full h-auto flex flex-col  justify-between items-center gap-4 pl-8'>

                    <div className='w-full m-h[100px] flex justify-start items-center mb-4  lg:pt-2'>
                        <p className='text-[32px] lg-[36px] font-bold hidden md:block lg:block pl-4'>Benefits</p>
                        <p className='text-[30px] lg-[36px] font-bold md:hidden lg:hidden xl:hidden block pt-[64px] md:pt-0 lg:pt-0 px-4 md:px-0 lg:px-0'>Pre-booking Benefits</p>
                    </div>

                    <div className='w-full min-h-[400px] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 justify-items-center items-center'>
                        {FMImages.map(({ FMIMG, content1, content2 }, index) => {
                            return (
                                <div 
                                    className='w-full h-full flex flex-row justify-start  items-start md:flex-row md:justify-start  md:items-start lg:flex-col lg:justify-start lg:items-start p-2 md:p-1 lg:p-4 md:gap-6 gap-6' 
                                    key={index}
                                >
                                    <img src={FMIMG} alt='' className='w-[66px] h-[64px] mt-2 md:mt-2 lg:mt-0'/>
                                    <div className='flex flex-col justify-start items-start w-full h-full '>
                                        <p className='text-[18px] md:text-[22px]  font-bold mt-2'>{content1}</p>
                                        <p className='text-[16px] md:text-[18px] lg:text-[18px] '>{content2}</p>
                                    </div>
                                </div>
                            );
                        })}
                        </div>
                </div>


                <div className='w-full h-auto flex justify-center flex-col items-center pt-8 pl-4 md:pl-0 lg:pl-0'>
                      
                       <form className='mx-4 mt-0 lg:mt-14 md:mt-14 w-full h-auto bg-[#F5FAFE] rounded-[30px] p-[30px] md:p-[45px] lg:p-[64px]' onSubmit={handleFormSubmit}>
                            <div className='w-full h-auto'>
                                <p className='text-[18px] font-semibold'>Get started by filling in the details below to recieve your first year free with exclusive pre-booking benefits</p>
                            </div>

                            <div className='w-full h-auto my-8'>
                                <label className='w-full  text-[16px]'>Full Name</label>
                                <input type="text" name='name' className="w-full h-10 p-2 rounded-[12px] border border-[gray]" placeholder='Enter your Name' autoComplete='off'
                                onChange={handleInputChange}
                                value={formData.name}
                                required
                                />
                            </div>

                            <div className='w-full h-auto my-8'>
                                <label className='w-full text-[16px]'>Email</label>
                                <input type="email" name='email' className="w-full h-10 p-2 rounded-[12px] border border-[gray]" placeholder='Enter your email' autoComplete='off'
                                onChange={handleInputChange}
                                value={formData.email}
                                required
                                />
                            </div>

                            <div className='w-full h-auto my-8'>
                                <label className='w-full  text-[16px]'>Mobile</label>
                                <input type="number" name='phone' className="w-full h-10 p-2 rounded-[12px] border border-[gray]" placeholder='Enter your mobile number' autoComplete='off'
                                onChange={handleInputChange}
                                value={formData.phone}
                                required
                                />
                            </div>
                            <div className='w-full h-auto mt-8'>
                                <button type='submit' className='w-full h-auto bg-[#00B4D8] py-2 rounded-[12px] text-white text-[24px] font-semibold'>Submit</button>
                            </div>

                        </form>
                </div>
            </div>
        </div>
        <Footer/> 
       </div>
    </div>
  )
}

export default Form