import React from 'react'
// import MouseIcon from '../Static/Image/MouseIcon.png'
import MouseIcon from '../Static/Image/HerosectionA/MouseIcon.svg'

const HeroSectionA = () => {
  return (
    <div className='w-full h-auto'>
      <div className='max-w-[1280px] m-auto'>
      <div className='w-full h-auto flex justify-center items-center'>
            <div className='flex flex-col w-full justify-between gap-1 px-4 items-center'>

                <div className='md:max-w-[1123px] md:max-h-[80px] max-w-[864px] max-h-[80px] md:mt-[228px] lg:mt-[228px] mt-[228px] sm:text-center lg:flex-row flex flex-col justify-center items-center mx-auto gap-8 md:gap-8 lg:gap-4 lg:mb-[20px]'>
                  <span className='relative sm:max-w-[376px] sm:max-h-[105px] max-w-[236px] max-h-[66px] flex flex-col justify-center items-center border-2 border-black px-4 '>
                      <span className='text-[#00B4D8] md:text-[64px] text-[36px] text-center font-bold duration-400 w-auto'>Simplifying</span>

                      {/* Corner Handles */}
                      {/* Top-Left Corner Handle */}
                      <div className="absolute top-[-5px] left-[-5px] w-4 h-4 bg-white border-2 border-black"></div>
                      
                      {/* Top-Right Corner Handle */}
                      <div className="absolute top-[-5px] right-[-5px] w-4 h-4 bg-white border-2 border-black"></div>
                      
                      {/* Bottom-Left Corner Handle */}
                      <div className="absolute bottom-[-5px] left-[-5px] w-4 h-4 bg-white border-2 border-black"></div>
                      
                      {/* Bottom-Right Corner Handle */}
                      <div className="absolute bottom-[-5px] right-[-5px] w-4 h-4 bg-white border-2 border-black"></div>

                      {/* Mouse Icon */}
                      <div className="absolute left-[60%] top-[80%] md:left-[70%] md:top-[90%]">
                        <img src={MouseIcon} alt='mouseIcon' className='w-[50px] h-[40px]'/>
                      </div>
                  </span>

                  
                  <p className='md:text-[64px] text-[36px] text-center font-bold w-auto'> Digital for Hotels</p>
                </div>
               </div>
      </div>
      </div>
    </div>
  )
}

export default HeroSectionA