import React from 'react';
import B1 from '../Static/Image/SectionC/Image1.svg';
import B2 from '../Static/Image/SectionC/Image2.svg';
import B3 from '../Static/Image/SectionC/Image3.svg';
import B4 from '../Static/Image/SectionC/Image4.svg';
import B5 from '../Static/Image/SectionC/Image5.svg';
import B6 from '../Static/Image/SectionC/Image6.svg';
import B7 from '../Static/Image/SectionC/Image7.svg';
import B8 from '../Static/Image/SectionC/Image8.svg';

const HeroSectionC = () => {
    const BImages = [
        { BIMG: B1, content1: 'Reduce Marketing Costs', content2: 'Automate ad creation and outreach.' },
        { BIMG: B2, content1: '4x Customer Growth', content2: 'Attract more guests with AI-driven strategies.' },
        { BIMG: B3, content1: 'Double Your Revenue', content2: 'Unlock untapped revenue potential.' },
        { BIMG: B4, content1: 'Boost Retention by 200%', content2: 'Keep guests coming back.' },
        { BIMG: B5, content1: 'Maximize Earnings', content2: 'Stop leaving money on the table.' },
        { BIMG: B6, content1: 'Stress-Free Scaling', content2: 'AI-driven dynamic pricing and growth strategies.' },
        { BIMG: B7, content1: 'Freedom from OTAs', content2: 'Control your listings - no need to rely on Online Travel Agencies.' },
        { BIMG: B8, content1: 'Keep More Revenue', content2: 'Avoid hefty OTA commission fees.' },
    ];

    return (
        <div className='w-full h-auto'>
         <div className='max-w-[1280px] m-auto pt-[64px] md:pt-[64px] lg:pt-[64px]'>
            <div className='w-full  h-auto m-auto px-4 md:px-0 lg:px-0 '>
            <div className='w-auto h-full flex flex-col justify-center items-center md:pl-4 sm:pl-2 py-15 md:mx-10  mx-4 '>
                <div className='w-full flex flex-col justify-start items-start mt-4'>
                    <h1 className='text-[36px] md:text-[48px] lg:text-[64px] text-left mb-2 bg-gradient-to-r from-[#008099] to-[#00B4D8] bg-clip-text text-transparent font-extrabold'>Benefits</h1>
                </div>

                <div className='w-full h-auto grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8 py-6'>
                    {BImages.map((item, index) => {
                        return (
                            <div className='w-auto h-full flex md:flex-col flex-row md:justify-start md:items-start justify-self-stretch items-start pt-4 gap-8' key={index}>
                                <img src={item.BIMG} className='w-[66px] h-[64px] sm:mt-1' alt='' />
                                <div className='w-auto lg:pt-0 md:pt-2 sm:pt-8'>
                                    <p className='text-[18px] md:text-[22px] text-left font-semibold'>{item.content1}</p>
                                    <p className='text-[16px] md:text-[18px] text-left '>{item.content2}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
        </div>
     </div>
    );
}

export default HeroSectionC;
