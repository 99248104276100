import Layout from "./Components/Layout";
import { Routes,Route } from "react-router-dom";
import Form from "./Components/Form";
function App() {
  return (
    <div className="w-full h-auto bg-white">
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/client-info" element={<Form/>} />
      </Routes>
    </div>
  );
}

export default App;
