import React from 'react'
import subs_1 from '../Static/Image/SectionG/Sub1.svg'
import subs_2 from '../Static/Image/SectionG/Sub2.svg'
import subs_3 from '../Static/Image/SectionG/Sub3.svg'
import subs_4 from '../Static/Image/SectionG/Sub4.svg'
import './style.css';


const HeroSectionG = () => {
    const subs_data = [
        { image: subs_1, content1: 'Exclusive Discounts', content2: 'Save big as an early adopter.' },
        { image: subs_2, content1: 'Priority Support', content2: 'Enjoy VIP customer support.' },
        { image: subs_3, content1: 'Beta Feature Access', content2: 'Get early access to new tools.' },
        { image: subs_4, content1: 'Free StratergY Session', content2: 'Receive a complimentary consultation to kickstart your success.' },];


        // Function to navigate to /client-info
        const handleSubscribeForm = (e) => {
          e.preventDefault();
          window.location.href = '/client-info';
        };

  return (
    <div className='w-full h-auto'>
    <div className='max-w-[1280px] m-auto'>
    <div className='w-full h-auto my-10'>
      <div className='w-full h-auto m-auto  px-4 lg:px-0 md:px-4'>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 md:gap-1 lg:gap-4 m-5 lg:m-10 lg:px-12 md:px-2 bg-[#F5FAFE] lg:py-10 lg:rounded-[48px] md:rounded-[36px] rounded-[24px]">

          <div className=" col-span-1 md:col-span-1 p-5 lg:p-6 order-2 lg:order-1 md:mt-4">
              <p className="font-semibold text-[32px] md:text-[32px] lg:text-[32px] hidden md:flex text-center md:text-left lg:text-left md:pt-8 lg:pt-0">Early Subscription Benefits</p>
              
              <form className='md:mt-10 lg:mt-2 mt-2 md:pt-10 lg:pt-0 pt-0'>
              <input type="text" name="subscribe" placeholder="Email Id" className="p-2 lg:mt-10 rounded-[12px] border-2" style={{width:'100%'}} required/>
              <button  className="mt-[12px] bg-[#00B4D8] text-white font-semibold p-2 rounded-[12px]" style={{width:'100%'}} onClick={handleSubscribeForm}>Subscribe</button>
              </form>
              <p className="text-[14px] md:text-[16px] lg:text-[16px] text-[#00B4D8] mt-6 md:mt-6">Transform your hotel's digital presence with Torqoise. Subscribe now and stay ahead of the component.</p>
          </div>


          <div className=" col-span-1 md:col-span-1 lg:col-span-2 p-3 md:pt-10 md:pl-6 lg:pt-8 order-1 md:order-2">

            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-12 md:gap-6 md:mb-2">
                <p className=" col-span-1 font-semibold text-[22px] md:text-[36px] lg:text-[36px] flex md:hidden mt-8 text-center md:text-left lg:text-left w-full ">Early Subscription Benefits</p>

                {subs_data.map((item,index)=>{
                    return(
                    <div className=" col-span-1 w-full h-full flex flex-row md:flex-row lg:flex-col justify-between md:justify-between lg:justify-start  items-start md:items-start   lg:items-start p-2 md:gap-3 gap-6 subscription" key={index}>
                    <img src={item.image} width="64px" className="mt-2 md:mt-2 lg:mt-0"/>
                        <div className='w-full flex flex-col justify-start items-start '>
                        <p className="font-semibold text-[18px] md:text-[20px] lg:text-[22px]">{item.content1}</p>
                        <p className="text-[16px] md:text-[16px] lg:text-[18px]">{item.content2}</p>   
                        </div>               
                    </div>
                    )
                })}


            </div>

          </div>

          </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default HeroSectionG